<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table ref="table" multiple :sst="true" v-model="selected" :max-items="itemsPerPage" search
      @search="(val) => (searchQuery = val)" :data="users">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <!-- ITEMS PER PAGE -->
        <div @click="exportPdf" class="
            btn-add-new
            p-3
            mb-4
            mr-4
            rounded-lg
            cursor-pointer
            flex
            items-center
            justify-center
            text-lg
            font-medium
            text-base text-primary
            border border-solid border-primary
          ">
          <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />

          <span class="ml-2 text-base text-primary">تقرير الصالات</span>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="title">الإسم</vs-th>
        <vs-th sort-key="phone">رقم الهاتف</vs-th>
        <vs-th sort-key="isBanned">حظر المستخدم</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="user" v-for="(user, index) in data" :key="index">
            <!-- in all these place holders replace them with {{contest.ObjectHere}} -->

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ user.username }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ user.phone }}</p>
            </vs-td>

            <vs-td>
              <vs-switch val="html" v-model="user.is_active" @change="setBan(user.id, user.is_active)">
              </vs-switch>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination class="mt-4" :total="lastPage" v-model="current_Page" @change="getData(current_Page)">
    </vs-pagination>

    <!-- HIDDEN TABLE OF ORDERS USED FOR PRINTING PDF-->
    <div style="display: none" id="table_body">

      <table id="table_products_pdf" style="width: 100%">
        <thead id="table_products_pdf_header" style="background-color: black">
          <tr id="table_products_pdf_header_row"></tr>
        </thead>
        <tbody id="table_body">
          <tr style="width: 100%; margin-top: 1rem; margin-bottom: 1rem" v-for="(data, index) in reportData"
            :key="index">
            <td style="color: black; font-weight: bold; text-align: center">
              {{ data.code }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{data.belongs_to }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              <div >
                {{ data.users }} user
              </div>
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ data.orders }} order
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moduleUser from "@/store/users/moduleUser.js";

export default {
  data() {
    return {
      selected: [],
      isMounted: false,
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      //manually filled array of data
      users: [],
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      searchQuery: "",
      params: {},
      reportData:[]
    };
  },
  watch: {
    searchQuery: {
      handler(newVal) {
        this.params["starts_with[phone]"] = newVal;
        this.getData(1);
      },
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.users.length;
    },
  },
  methods: {
    async exportPdf() {
      var columns = [
        "Code",
        "Belongs to",
        "Users Count",
        "Orders Count",
      ];
      // get the products

      await this.$store.dispatch("getCodeReport").then((response) => {
        this.reportData = response.data.report

        setTimeout(() => {
          let printContents = document.getElementById("table_body");
          printContents.style.display = "table";
          var windosw = window.open();
          windosw.document.body.innerHTML = printContents.innerHTML;
          printContents.style.display = "none";

          let style = document.createElement("style");
          style.innerHTML =
            " @import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap'); body:{ font-family: 'Cairo' } #table_products_pdf_header > tr >th { color: white !important; font-size: 14px; text-align: center; } @page { size: A4; margin: 11mm 17mm 17mm 17mm; } @media print { thead{ -webkit-print-color-adjust: exact; background-color: black; } footer { position: fixed; bottom: 0; } #table-header{ position: fixed; top: 0; left: 0; } html, body { width: 210mm; height: 297mm; -webkit-print-color-adjust: exact; } }";
          windosw.document.body.style.fontFamily = "Cairo";
          windosw.document.head.appendChild(style);

          columns.forEach((h) => {

            let th = windosw.document.createElement("th");
            th.innerHTML = h;
            windosw.document
              .getElementById("table_products_pdf_header_row")
              .appendChild(th);
          });

          setTimeout(() => {
            //windosw.print()
          }, 100);
        }, 800);
      });
    },
    getData(page) {
      this.params["page"] = page;
      this.$store
        .dispatch("user/fetchDelivery", this.params)
        .then((response) => {
          this.users = response.data.delivery.data;
          this.itemsPerPage = response.data.delivery.per_page;
          this.totalItems = response.data.delivery.total;
          this.lastPage = response.data.delivery.last_page;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: e.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    setBan(id, ban) {
      var bodyFormData = new FormData();
      bodyFormData.set("id", id);
      bodyFormData.set("status", ban ? 1 : 0);

      this.$store
        .dispatch("user/changeStatus", bodyFormData)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تعديل حالة المستخدم بنجاح",
            color: "success",
          });
          this.getData(1);
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("user", moduleUser);
      moduleUser.isRegistered = true;
    }
    this.getData(1);
  },
  mounted() {
    this.$vs.loading();
    this.isMounted = true;
  },
};
</script>








<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .contest-picture {
      max-width: 100px;
      max-height: 100px;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
